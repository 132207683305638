import React from 'react';
import { Layout } from '../../components/Layout';

const RelaxationAndEncroachmentInfo2022 = () => (
  <Layout
    title="Relaxation and Encroachment Information updated for 2022"
    description=""
    keywords=""
  >
    <main>
      <h1>Relaxation and Encroachment Information updated for 2022</h1>

      <p>
        <strong>
          What is an encroachment agreement/encroachment consent letter and why are they required?
        </strong>
      </p>

      <p>
        Encroachment agreements are legal agreements between two parties that resolve the issue
        created when the improvements made by one party encroach onto the property (or rights) of
        another party. “Improvements” used in this context usually refer to a structure such as a
        fence, deck, retaining wall, or other construction that increases the value of the property.
        Most commonly, encroachment agreements are required when property improvements are
        encroaching on municipal property or a right of way granted to the municipality.
        Encroachment agreements may also be made between neighbors, these are usually termed private
        encroachment agreements.
      </p>

      <p>
        Encroachments may go many years, even decades without being noticed or causing issues and in
        fact, often do. However when you go to sell your home the encroachments certainly may cause
        some undue stress and delays in the attempts to rectify the situation. To execute the real
        estate transaction the seller will be required to provide a Real Property Report (RPR) to
        the buyer. The Land Surveyor will identify any and all encroachments on the Real Property
        Report (RPR). Subsequently, the RPR will be submitted for evaluation to the municipality.
        This evaluation process is known as getting a stamp of compliance (also known as a letter or
        certificate of compliance). At this point the municipality will require some resolution to
        the encroachments, and will put an encroachment advisory on the report to indicate an
        unresolved issue relating to encroachments on the property. This will have to be resolved
        either through an encroachment agreement, or by removing/moving the encroachment. Because of
        the high cost of construction and demolition, the encroachment agreement is usually the
        cheaper, preferable option.
      </p>

      <p>
        <strong>
          How can an encroachment agreement/encroachment consent letter or advisory affect the sale
          of my property?
        </strong>
      </p>

      <p>
        Encroachment advisories, agreements, and consent letters typically do not stop the sale of
        your property. If the encroachment agreement has not been finalized at the time you are
        conveying the property sale, it is typical for the buyer to request a hold back of some of
        the funds from the sale. The size of the hold back depends mostly on the nature of the
        encroachment; a larger, more expensive encroachment would warrant a larger holdback, whereas
        a small encroachment that is more simple to resolve would warrant a smaller holdback.
        Holdback amounts usually range from $5,000 to $15,000. This money is held by the lawyers in
        trust until the encroachment is resolved, which is usually indicated by the RPR receiving a
        Certificate of Compliance *without* the encroachment advisory (or the advisory being
        “voided”) from the municipality.
      </p>

      <p>
        It is important to know that the buyer can place a condition on the purchase in regards to
        encroachments and encroachment agreements OR they can accept the RPR without compliance. The
        latter of these is a very rare circumstance as the mortgaging bank often refuses to mortgage
        a property that is lacking full compliance, and it is generally in the buyer's best
        interests to require this from the seller. One case that it might be reasonable for a buyer
        to accept a property without the issue being resolved, is if they plan to demolish the
        property to build an infill development, in this case the entire property is usually
        rebuilt, and so the encroachment is of little concern as it will ultimately be removed.
      </p>

      <p>
        An encroachment agreement will take quite some time to complete, so it is best to expect
        lengthy delays ranging between 4 and 6 months to be completed and registered. While the city
        will quote faster timelines then this, however in our experience these timelines are not
        even close to realistic. The encroachment agreement is a document that gets registered at
        the Alberta Land Titles Office. Because of this, the agreement does have certain
        requirements such as original “wet” signatures, no digital signing, witness signatures, and
        a Commissioner for Oaths. Because of all these requirements and steps, encroachment
        agreements are not usually as simple to execute as most people assume. The last thing to
        note about these agreements, is that once offered, it is a matter of submitting the forms
        and it will be registered, it will not be rejected after the fact.
      </p>

      <p>
        <strong>What is a relaxation application and why is it required?</strong>
      </p>

      <p>
        Relaxation applications are required when an improvement on the property (building, garage,
        retaining wall, deck, fence, etc.) does not meet the requirements of a municipal bylaw. The
        municipal bylaws are the arbitrary bylaws that the council creates. It is a common
        misunderstanding that they are “building codes”; they are not. A very common comment from
        our clients that they think they will have issues with obtaining the compliance certificate
        because the railing or steps on the deck are “Not to code”, however the municipality will
        only be evaluating the deck’s compliance with their own bylaws, which generally relate only
        to height and location on the property and to do not make mention of building codes.
        Applications for relaxations can take 6-8 weeks to complete, but in some locations can take
        much longer. Relaxation applications, are truly applications, they can be rejected by the
        city, or approved with conditions, though most applications are approved without conditions.
      </p>

      <p>
        A relaxation may also be called a variance. If you encounter this term, you can assume that
        variance and relaxations are synonyms.
      </p>

      <p>
        <strong>How can a relaxation/variance application affect the sale of my property?</strong>
      </p>

      <p>
        Relaxation applications, and the associated delays, do not normally stop the sale of your
        property. If the processing time for the relaxation goes past your closing date, and given
        the significant delays at the city it likely will, the buyer will ask for a hold back of
        some of the funds (typically a few thousand) until the Certificate of Compliance is issued.
      </p>

      <p>
        It is important to know that the buyer can place conditions on the purchase in regards to
        these relaxation issues OR they can also accept the RPR without compliance. The latter of
        these is a very rare circumstance as the mortgaging bank often will not mortgage the
        property without compliance. Accepting these deficiencies, like encroachment issues, is
        usually only done with the intent to develop an infill, and thus the existing structures
        will be demolished anyway; in all other cases is it best to resolve the issues at time of
        sale.
      </p>
    </main>
  </Layout>
);

export default RelaxationAndEncroachmentInfo2022;
